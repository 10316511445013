<template>
  <div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12">
        <navbar-layout />
      </div>
    </div>
  </div>
  <main class="main-content mt-0 ps">
    <!-- <router-view/> -->
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
import NavbarLayout from './NavbarComponent.vue'

export default {
  name: 'App',
  components: {
    NavbarLayout
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .user-dashboard{
    padding-top: 0px;
  }
}

@media only screen and (max-width: 325px) {
  .user-dashboard{
    padding-top: 30px !important;
  }
}
</style>
