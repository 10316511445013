import { notify } from '@kyvg/vue3-notification'




function loader( area , status )
{
    if(area)
    {
        if(status)
        {
            area.style.opacity = 0.3
            let divLoader = document.createElement("div");
            divLoader.classList.add('div-loader')
            let loader =document.createElement("div");
            loader.classList.add('loader')
            let loaderWheel = document.createElement("div");
            loaderWheel.classList.add('loader-wheel')
        
            divLoader.appendChild(loader)
            loader.appendChild(loaderWheel)
        
            if(area)
            area.prepend(divLoader)
            else
            return null
        }
        else
        {
            area.style.opacity = 1
            let remove = area.getElementsByClassName('div-loader')[0]
            if(remove)
            area.removeChild(remove)
        }
    }
}



function clearValidation( form )
{
    let elements = form.querySelectorAll('.error')
    elements.forEach(element => {
        element.innerHTML = ''
    })
}
// Validar formulario
function validateForm( form , errors )
{
    if(errors.message != 'This action is unauthorized.')
    {
        let elements = form.querySelectorAll('.error')
        elements.forEach(element => {
            if(errors.errors[element.getAttribute('error')])
            element.innerHTML = errors.errors[element.getAttribute('error')]
        })
    }
}
// Llenar formulario
function fillForm( form , data )
{
    let elements = form.elements
    for(const element of elements)
    {
        if(element.type !== 'file')
        {
            element.value = (data[element.getAttribute('name')] !== null) ? data[element.getAttribute('name')] : ''
        }
    }
}

// Resetear Formulario
function resetForm( form )
{
    form.reset()
}

// Obtencion de datos del formulario
const getFormData = ( form ) => {
    let data = {};
    
    // Leer los elementos del formulario
    let formElements = form.elements;
    for (const element of formElements) {
        if (element.name) {
            if (element.type === 'radio') {
                if (element.checked) {
                    data[element.name] = element.value;
                }
            } else {
                data[element.name] = element.value;
            }
        }
    }
    
    return data;
}

function createPopupWin(pageURL,pageTitle,popupWinWidth,popupWinHeight) 
{
    let left     = (screen.width - popupWinWidth) / 2;
    let top      = (screen.height - popupWinHeight) / 4;
    window.open(
        pageURL, 
        pageTitle, 
        'resizable=yes, width=' + popupWinWidth
        + ', height=' + popupWinHeight + ', top='
        + top + ', left=' + left);
}

function loading(element,action)
{
    let dom = document.getElementById(element)
    if(action)
    {
        dom.style.opacity = 0.7
        let divLoader =document.createElement("div");
        divLoader.classList.add('div-loader')
        let loader =document.createElement("div");
        loader.classList.add('loader')
        let loaderWheel = document.createElement("div");
        loaderWheel.classList.add('loader-wheel')
    
        divLoader.appendChild(loader)
        loader.appendChild(loaderWheel)
    
        if(dom)
        dom.prepend(divLoader)
        else
        return null
    }
    else
    {
        dom.style.opacity = 1
        let remove = dom.getElementsByClassName('div-loader')[0]
        if(remove)
        dom.removeChild(remove)
    }
}


function error(response, form = null)
{
    if(response == undefined)
    {
        notify({
            type: "error",
            duration: 2000,
            title: "<i class='fa fa-times me-2'></i>Upps",
            text: 'El servidor no responde, intentelo más tarde.',
            classes: "bg-success",
            closeOnClick: true,
            reverse: true
        });
    }
    else
    {
        if(response.status == 401)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>No autorizado.",
                text: 'No cuenta con los credenciales requeridos para realizar esta operación.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 403)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>Token expirado o inválido.",
                text: 'Inicie sesión nuevamente.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
            sessionStorage.clear
            window.location.href = window.location.origin
        }

        if(response.status == 422)
        {
            validateForm(form, response.data)
        }

        if(response.status == 423)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>Certificado sin fondo.",
                text: 'Verifique el fondo del certificado antes de enviarlo.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 424)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>Fondo no soportado.",
                text: 'El tipo de archivo no es válido.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 500)
        {
            notify({
                type: "error",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>El servidor de destino no responde.",
                text: 'Intente realizar esta operación más tarde.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
            
        }

        if(response.status == 513)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>No se completó el envio a Tocapu.",
                text: 'Verifique si existe la carpeta destino e intente realizar esta operación más tarde.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 514)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>Carpeta en uso ó no encontrada.",
                text: 'Esta carpeta esta en uso ó no existe, proceso no completado.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 515)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>No se puede realizar esta operación.",
                text: 'Operación interrumpida por consistencia de datos.',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }

        if(response.status == 516)
        {
            notify({
                type: "warn",
                duration: 2000,
                title: "<i class='fa fa-exclamation-circle me-2'></i>No se puede realizar esta operación.",
                text: 'Puntos Insuficientes',
                classes: "bg-success",
                closeOnClick: true,
                reverse: true
            })
        }
    }
}

export default {
    error,
    loader,
    clearValidation,
    validateForm,
    fillForm,
    resetForm,
    createPopupWin,
    getFormData,
    loading,
}
