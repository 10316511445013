<template>
  <div>
    <nav class="navbar nav-itec navbar-expand-lg top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
      <div class="container-fluid">
        <a class="navbar-brand font-weight-bolder ms-lg-0 ms-0 " href="">
          <img :src="'../assets/img/logo_web_blanco.png'" width="140">
        </a>
        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse"
          data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon mt-2">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navigation">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a class="dropdown-item bjar pt-0 pb-0" href="https://wa.me/51976993697" target="_blank" style="color: white;">
                            <i class="fa fa-question-circle-o me-1" aria-hidden="true" style="color: blue; background: white; border-radius: 50%;"></i>¿Necesita ayuda?
                        </a>
                    </li>
                    <li class="nav-item">
                      <a  class="dropdown-item btn bg-gradient-info btn-md mb-0"  href="https://certificadoitec.com/login"  target="_blank"  rel="noopener noreferrer" >
                        <i class="fa fa-user-o me-2" aria-hidden="true"></i>
                        Iniciar sesión
                      </a>
                    </li>
                </ul>
            </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'AppNav',
  methods: {
  
  },
}
</script>
<style>
.dropdown-item.bjar:hover, .dropdown-item.bjar:focus {
    color: #344767;
    background-color: transparent !important;
}</style>