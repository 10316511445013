import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/router";
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import jQuery from 'jquery'


const uri = {
    local: 'http://localhost:8000/api',
    production: 'https://certificadoitec.com/api'
}

window.$ = window.jQuery = jQuery
const app = createApp(App)

// * Uri a usar
app.config.globalProperties.$uri = uri.production
app.config.globalProperties.$swal = Swal


app.use(router)
   .mount('#app')
