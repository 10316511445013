<template>
    <form id="filtForm">
        <div class="input-group">
            <span class="input-group-text text-sm"><i class="fas fa-search me-2 text-info" aria-hidden="true"></i>Buscar</span>
            <input @input="writing" type="text" name="title" class="form-control text-dark form-control-sm ps-2" placeholder="Escribe el nombre del curso" autocomplete="off">
            <select @change="selected" name="category" class="form-control form-control-sm ps-2">
                <option value="" selected>- Elige una categoría -</option>
                <option  v-for="(element, index) in list_category" :key="index" :value="element.id">{{element.name}}</option>
            </select>
        </div>
    </form>
</template>

<script>
import vuenisimo from '../helpers/vuenisimo'
import axios from 'axios'
export default {
    data() {
        return {
            form : null,
            list_category: []
        }
    },

    mounted() {
        this.form = document.getElementById('filtForm')
        this.listCategory()
        this.formData()
    },

    methods: {
        formData : function()
        {
            let filts = vuenisimo.getFormData(this.form)
            this.$emit('filts',filts)
        },

        writing : function()
        {
            clearTimeout(this.timeout);
            let self = this
            this.timeout = setTimeout(function () {
                self.formData()
            }, 650);
        },

        selected : function()
        {
            this.formData()
        },

        async listCategory(){
            try {
                const response = await axios.get(this.$uri + '/gategory-all');
                this.list_category = response.data.data
            } catch (error) {
                console.error(error);
                vuenisimo.error(error.response);
            } 
        }
    },
}
</script>
