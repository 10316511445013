<template>
    <section class="content user-dashboard">
        <div class="container">
            <div class="mt-7">
                <div class="col-12 position-relative" id="session">
                    <div class="row">
                     
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="row mb-3 mt-3">
                                <div class="col-md-12 col-xs-12 mb-2">
                                    <find-component v-on:filts="filts"></find-component>
                                </div>
                                <!-- <div class="col-md-2 col-xs-12 text-end">
                                    <button @click="listing" class="btn btn-sm btn-info w-100 text-light"><i  class="fa fa-refresh fs-6 me-1" aria-hidden="true"></i>Refrescar</button>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <h5 class="text-light">Cursos Grabados:</h5>
                                </div>
                               <!--  <div class="col-6 text-end">
                                    <button @click="change('cards')" class="btn btn-info btn-sm me-2"><i  class="fa fa-th fs-6 text-dark" aria-hidden="true"></i></button>
                                    <button @click="change('table')" class="btn btn-info btn-sm"><i class="fa fa-th-list fs-6 text-dark" aria-hidden="true"></i></button>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-12 mt-4 position-relative" id="areaCard">
                                    <card-component v-show="squema == 'cards'" :data="list"
                                        v-on:unprocedure="unprocedure"
                                        v-on:procedure="procedure" v-on:aproved="aproved"
                                        v-on:emited="emited"></card-component>
                                   
                                    <orientation-component class="text-light" :data="list" />
                                    <paginate-component :data="list.links" v-on:page="paginate" />
                                </div>

                                <unprocedure-component v-on:recargar="recargar"  ref="UnprocedureComponent"></unprocedure-component>
                              

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FindComponent from './FindComponent.vue'
import CardComponent from './CardComponent.vue'
import OrientationComponent from '../components/OrientationComponent.vue'
import PaginateComponent from '../components/PaginateComponent.vue'
import UnprocedureComponent from './UnprocedureComponent.vue'

import vuenisimo from '../helpers/vuenisimo.js'
import axios from 'axios'


export default {
    data() {
        return {
            area: null,
            filters: {},
            list: {},
            page: 1,
            squema: 'cards',
            data: {
                id: null,
                firstname: null,
                lastname: null,
                email: null,
                score: null,
                aproved: null,
                emited: null,
                verificate: null,
                procedure: null,
                unprocedure: null,
                token: null,
            },
        }
    },

    components: {
        FindComponent,
        CardComponent,
        OrientationComponent,
        PaginateComponent,
        UnprocedureComponent,
    },


    created() {
        // this.$store.commit('SET_LAYOUT', 'UserLayout')
        this.area = document.getElementById('areaCard')
    },

    mounted() {
        /*  let data = JSON.parse(sessionStorage.getItem('user'))
         this.data.id = data.id
         this.data.firstname = data.firstname
         this.data.lastname = data.lastname
         this.data.email = data.email
         this.data.score = data.score
         this.data.aproved = data.aproved
         this.data.emited = data.emited
         this.data.verificate = data.verificate
         this.data.procedure= data.procedure
         this.data.unprocedure= data.unprocedure
         this.data.token = data.token*/
         vuenisimo.loader(this.area,true) 
    },

    methods: {
        change: function (type) {
            this.squema = type
        },

        filts: async function  (filts) {
            this.filters = filts
            await this.listing()
        },

        listing: async function () {
            vuenisimo.loading('areaCard', true);
           
            try {
                const response = await axios.post(this.$uri + '/recorded-events?page=' + this.page, this.filters);
                this.list = response.data;
            } catch (error) {
                vuenisimo.error(error.response);
            } finally {
                vuenisimo.loading('areaCard', false);
            }
        },

        unprocedure: function (element) {
            this.$refs.UnprocedureComponent.info(element)
        },

        redeemPoints: function (element) {
            this.$refs.RedeemPointsComponent.info(element)
        },

        recargar: async function (element) {
            let d = JSON.parse(sessionStorage.getItem('user'))
            this.data.score = d.score

            await this.listing();
            let data = this.list?.data?.find((e) => e.id === element);
            this.procedure(data);
        },

        procedure: function (element) {
            this.$refs.ProcedureComponent.procedure(element)
        },

        detailPoints: function (element) {
            console.log('detalle');
            this.$refs.PointsComponent.pintar(element)
        },

        aproved: function (element) {
            this.$refs.AprovedComponent.aproved(element)
        },

        emited: function (element) {
            this.$refs.EmitedComponent.emited(element)
        },

        paginate: function (page) {
            if (page.active == false && page.url != null) {

                let split = page.url.split('=')
                this.page = split[1];
                this.listing()
            }
        },
    }
}
</script>
<style scoped>
.icon-person {
    height: 50px;
}

.fil1 {
    fill: #002B43
}

.fil0 {
    fill: #004E79
}

.fil2 {
    fill: #00D6D6
}

.fil3 {
    fill: #FED7B0
}
</style>