<template>
    <div class="row" style="min-height: 255px;">
        <template  v-if="data.total > 0">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 text-center" v-for="(element, index) in data.data" :key="index">
                <div class="card-participation2 animate__animated animate__fadeIn">
                    <div class="participation-header d-flex justify-content-between" :style="'background-image: url('+element.image+');'">
                        
                        <small class="participation-stick fs-7 text-light" style="margin-left: 5px;">
                            <i class="fa fa-circle" style="color: green;" aria-hidden="true"></i>
                            Grabado
                        </small>
                      
                    </div>
                    <div class="participation-footer align-items-center">
                        <h6 class="participation-title fs-7 mb-0"> <b>{{element.title}}</b></h6>
                        <div class="btn-group btn-group-sm w-100 pt-1 ps-1 pe-1" style="margin-bottom: -10px;"  role="group" aria-label="Small button group">
                            <button type="button" @click="action(element.id)" class="btn btn-info" style="color: black;"> Más Detalles</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <h4 v-if="data.total == 0" class="text-center animate__animated animate__fadeIn fs-6">No encontramos registros, matriculate en nuestros cursos, <a class="text-info" href="https://itec123.com/" target="_blank">has click aqui!</a></h4>
            <h4 v-else class="text-center animate__animated animate__fadeIn fs-5">Buscando..</h4>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        data : Object,
    },
    data(){
        return{
            visible: false
        }
    },

    methods: {
        action: function(element)
        {
            this.unprocedure(element)
        },


        unprocedure: function(element)
        {
            this.$emit('unprocedure', element);
        },

    },
    mounted(){
      
    }
}
</script>
