let regions = [
    { id : '', text : '- Seleccionar -'},
    { id : 1, text : 'Amazonas'},
    { id : 2, text : 'Áncash'},
    { id : 3, text : 'Apurímac'},
    { id : 4, text : 'Arequipa'},
    { id : 5, text : 'Ayacucho'},
    { id : 6, text : 'Cajamarca'},
    { id : 7, text : 'Callao'},
    { id : 8, text : 'Cuzco'},
    { id : 9, text : 'Huancavelica'},
    { id : 10, text : 'Huánuco'},
    { id : 11, text : 'Ica'},
    { id : 12, text : 'Junín'}, 
    { id : 13, text : 'La Libertad'}, 
    { id : 14, text : 'Lambayeque'}, 
    { id : 15, text : 'Lima'}, 
    { id : 16, text : 'Loreto'}, 
    { id : 17, text : 'Madre de Dios'}, 
    { id : 18, text : 'Moquegua'}, 
    { id : 19, text : 'Pasco'}, 
    { id : 20, text : 'Piura'}, 
    { id : 21, text : 'Puno'}, 
    { id : 22, text : 'San Martín'}, 
    { id : 23, text : 'Tacna'}, 
    { id : 24, text : 'Tumbes'}, 
    { id : 25, text : 'Ucayali'},
    { id : 26, text : 'Otro País'},
]
export default regions