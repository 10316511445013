<template>
    <div v-show="data" class="mt-3">
        <small class="fs-6">Mostrando registros del {{(data.from) ? data.from : 0}} al {{(data.to) ? data.to : 0}} de un total de {{(data.total) ? data.total : 0}} registro(s)</small>
    </div>
</template>

<script>
export default {
    props: {
        data : Object
    },
}
</script>