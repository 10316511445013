<template>
    <div class="modal fade" id="payModal" tabindex="-1" aria-hidden="true" ref="UnprocedureComponent">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" id="prices">
                    <div class="row" >
                        <div class="col-12" >
                            <form @submit="pay" ref="payForm" id="payForm" style="position: relative">
                                <div class="row mb-2" v-if="at == 1">

                                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <h5>Curso: {{ curso_detalle?.title }}</h5>
                                        <table style="margin-left: 10px; font-size: 14px;">
                                            <tr>
                                                <td><div class="circle" style="width: 20px; height: 20px;">1</div></td>
                                                <td>Compra tu curso</td>
                                            </tr>
                                            <tr>
                                                <td><div class="circle" style="width: 20px; height: 20px;">2</div></td>
                                                <td>Rinde tu examen</td>
                                            </tr>
                                            <tr>
                                                <td><div class="circle" style="width: 20px; height: 20px;">3</div></td>
                                                <td>Obtén tu certificado</td>
                                            </tr>
                                           
                                        </table>
                                      
                                      
                                        <b>Incluye:</b>
                                        <table style="margin-left: 10px; font-size: 14px;">
                                            <tr>
                                                <td><i class="fa fa-check fs-6" aria-hidden="true" style="color: green;"></i></td>
                                                <td>Acceso inmediatos a todos los recursos (pdf y videos)</td>
                                            </tr>
                                            <tr>
                                                <td><i class="fa fa-check fs-6" aria-hidden="true" style="color: green;"></i></td>
                                                <td>Certificado con firma electrónica y código QR (Previo Examen)</td>
                                            </tr>
                                            <tr>
                                                <td><i class="fa fa-check fs-6" aria-hidden="true" style="color: green;"></i></td>
                                                <td>Materiales Plus</td>
                                            </tr>
                                            <tr>
                                                <td><i class="fa fa-check fs-6" aria-hidden="true" style="color: green;"></i></td>
                                                <td>Acceso 24/7</td>
                                            </tr>
                                        </table>

                                        <b>Temario:</b>
                                        <div v-html="curso_detalle?.syllabus" class="lista"></div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <h5>Información adicional:</h5>
                                        <div class="linea-horizontal"></div>

                                        <div  class="temario">
                                            <ul >
                                                <li>En el certificado el periodo de desarrollo del curso es: <b>{{ curso_detalle?.start }}</b> al <b>{{ curso_detalle?.end }}</b></li>
                                                <li>La fecha de emisión es la fecha actual y es el día que se firma el certificado</li>
                                                <li>El número de horas es: <b>{{ curso_detalle?.duration }} horas</b> </li>
                                                <li>Para la obtención del certificado es necesario aprobar con una calificación mayor a  14</li>
                                                <li>Cualquier pregunta adicional puede realizar por WhatsApp al <a style="color: blue;" href="https://wa.me/51976993697" class="btn-link" target="_blank">976993697</a> </li>
                                            </ul>
                                        </div>

                                        <h5>Elige tu certificado:</h5>
                                        <div class="linea-horizontal"></div>
                                        <div style="margin-left: 28px;">
                                            <div class="form-check mb-3" v-for="(element, index) in curso_detalle?.qualities" :key="index" >
                                                <input class="form-check-input" type="radio" name="qualitie_id" :id="'qualitie_id_' + element.id" :value="element.id" @change="handleSelectionChange(element.id)">
                                                <label class="custom-control-label" :for="'qualitie_id_' + element.id">{{ element.title }} ({{ element.pem }} soles)</label>
                                                <p style="font-size: 14px;" v-if="element.select">{{ element.description }} <a v-if="element.example_preview" @click.prevent="handleClick(element.example_preview)" href="" style="color: blue;"  class="btn-link" >Ver modelo</a> </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                 <div class="row mb-2" v-if="at == 2">

                                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                    
                                        <h6>Certificado : {{ certificado_seleccionado?.title }}</h6>
                                        <h6>Precio : S/.{{ certificado_seleccionado?.pem }} soles</h6>
                                        <br>
                                        <h5>Datos Personales</h5>

                                       <div>
                                            <div class="mb-1">
                                                <small for="" class="fs-6">DNI o Cédula de identidad* :</small>
                                                <div class="input-group">
                                                    <input @input="changes()" :disabled="loader" type="text" name="document" class="form-control" required minlength="8" maxlength="20" autocomplete="off">
                                                    <button @click="search()" class="btn btn-sm btn-success mb-0" type="button">
                                                        <span v-if="loader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                       {{ (loader) ? ' Loading...' : 'Buscar' }}
                                                    </button>
                                                </div>
                                                <p error="document" class="error"></p>
                                            </div>

                                            <div class="mb-1">
                                                <small for="" class="fs-6">Email* :</small>
                                                <input :disabled="dissabled" type="email" name="email" class="form-control" required maxlength="30" autocomplete="new-email">
                                                <p error="email" class="error"></p>
                                            </div>

                                            <div class="mb-1">
                                                <small for="" class="fs-6">Nombre(s)* :</small>
                                                <input  :disabled="dissabled"  type="text" name="firstname" class="form-control " required  autocomplete="new-first">
                                                <p error="firstname" class="error"></p>
                                            </div>
                                
                                            <div class="mb-1">
                                                <small for="" class="fs-6">Apellidos* :</small>
                                                <input  :disabled="dissabled"  type="text" name="lastname" class="form-control " required  autocomplete="new-lastname">
                                                <p error="lastname" class="error"></p>
                                            </div>
                                                
                                        </div>
                                    </div>    
                                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                        

                                        <div class="mb-1">
                                            <small for="" class="fs-6">Tel/Celular :</small>
                                            <input  :disabled="dissabled"  type="text" name="phone" class="form-control " minlength="9" maxlength="9" autocomplete="off">
                                            <p error="phone" class="error"></p>
                                        </div>

                                        <div class="mb-1">
                                            <small class="fs-6">Región o País :</small>
                                            <select   :disabled="dissabled"  name="region" class="form-control" required>
                                                <option  v-for="(element, index) in regions" :key="index" :value="element.id">{{element.text}}</option>
                                            </select>
                                            <p error="region" class="error"></p>
                                        </div>

                                        <div class="mb-1">
                                            <small class="fs-6">Método de Pago :</small>
                                            <select name="method" class="form-control" required  @change="handleMethodChange">
                                                    <option  value="">Seleccionar</option>
                                                    <option  v-for="(element, index) in metodos_pagos" :key="index" :value="element.id">{{element.title}}</option>
                                            </select>
                                            <p error="method" class="error"></p>
                                        </div>

                                        <div class="mb-1" v-if="selected_method != null">
                                            <div class="row d-flex align-items-center justify-content-center">
                                                <div class="col-6">
                                                    <div class="card-header">
                                                        <img :src="removeApiFromUrl($uri) + '/methods/' + selected_method?.logo" class="w-100">
                                                    </div>
                                                </div>
                                                <div class="col-6">{{ selected_method?.description }}</div>
                                            </div>
                                        </div>

                                        <div class="mb-1">
                                            <small class="fs-6">Envíanos tu voucher(Captura, Foto ó Pdf) :</small>
                                            <input type="file" class="form-control form-control-sm mt-2 w-100" name="file" accept=".pdf,.png,.jpg,.jpeg" data-allowed-file-extensions="png,jpg,jpeg,pdf" required>
                                            <p error="voucher" class="error"></p>
                                        </div>
                                        <div class="mb-1" style="text-align: center;">
                                            <button type="submit" class="btn btn-lg btn-success mb-0 mt-2 btn-block"><i class="fa fa-check-circle-o me-1 fs-5" aria-hidden="true"></i>Procesar Compra</button>
                                        </div>
                                    </div>
                                </div>
                           
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-sm btn-secondary mb-0 mt-2  me-2" data-bs-dismiss="modal" @click="reset()">Cerrar</button>
                            <button type="button" class="btn btn-sm btn-primary mb-0 mt-2" @click="pase(2)" v-if="at == 1">Siguiente <i class="fa fa-arrow-circle-right fs-7" aria-hidden="true"></i></button>
                            <button type="button" class="btn btn-sm btn-primary mb-0 mt-2" @click="pase(1)" v-if="at == 2"><i class="fa fa-arrow-circle-left fs-7" aria-hidden="true"></i> Atras</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vuenisimo from '../helpers/vuenisimo'
import regions from '../helpers/regions.js'
import axios from 'axios'
export default {
   
    data() {
        return {
            at : 1,
            id: null,
            curso_detalle: {},
            certificado_seleccionado: {},
            regions : null,
            metodos_pagos : [],
            dissabled : true,
            loader: false,
            title: 'Detalles - Cursos Grabados',
            selected_method: null
        }
    },

    mounted() {
        this.regions = regions
        this.form = document.getElementById('payForm')
    },

    methods: {
       

        info: function(id)
        {   
            this.id = id
            this.show()
            window.$(this.$refs.UnprocedureComponent).modal('show');
        },

        async show(){
            try {
                vuenisimo.loading('prices',true)
                const response = await axios.get(this.$uri + '/recorded-events-show/'+this.id);
                this.curso_detalle = response.data
                this.metodos_pagos = this.curso_detalle?.methods
                vuenisimo.loading('prices',false)
            } catch (error) {
                console.error(error);
                vuenisimo.error(error.response);
                /* this.$swal.fire({
                icon: "error",
                title: error.response,
                }); */
            } 
        },

        pase: function(pase)
        {   
          
            let form = vuenisimo.getFormData(this.form)
           
            if(form?.qualitie_id == undefined && pase == 2){
                this.$swal.fire({
                    icon: "warning",
                    title: "Seleccione un certificado",
                    });
                return
            }


            if(pase == 2){
                const idBuscado = form?.qualitie_id;
                const objetoEncontrado  =   this.curso_detalle.qualities.find((c) =>{
                                            return c.id == idBuscado
                                            });
                this.certificado_seleccionado   = objetoEncontrado
                this.title = 'Detalles del Pedido - Cursos Grabados'

            }
            this.at = pase
            if(this.at == 1){
                this.title=  'Detalles - Cursos Grabados'
                if(this.certificado_seleccionado){
                    let me = this;
                    setTimeout(function() {
                        // Obtener el formulario
                        let form = document.getElementById('payForm');

                        // Acceder al elemento deseado usando getElementById directamente
                        let qualitieIdElement = form.querySelector('#qualitie_id_'+ me.certificado_seleccionado?.id);

                        // Verificar si el elemento existe y luego marcarlo como checked
                        if (qualitieIdElement) {
                            qualitieIdElement.checked = true;
                        }
                    }, 150); // 500 milisegundos de retraso
                }
            }
        },


        pay: async function(event)
        {
            event.preventDefault()
            vuenisimo.loader(this.form, true)
            let data = new FormData()

            let form = vuenisimo.getFormData(this.form)
           
            data.append('id', this.id)
            data.append('qualitie_id',this.certificado_seleccionado.id)
            let file = this.form[8].files[0]
            data.append('voucher',file)
            data.append('method',form?.method )
            data.append('firstname',form?.firstname )
            data.append('lastname',form?.lastname )
            data.append('region',form?.region )
            data.append('document',form?.document )
            data.append('email',form?.email )
            data.append('phone',form?.phone )
            data.append('monto',this.certificado_seleccionado?.pem )

            await axios.post(this.$uri+'/recorded-events/store-user',data)
            .then( () => {
                window.$(this.$refs.UnprocedureComponent).modal('hide');
                this.at = 1
                this.id= null
                this.curso_detalle= {}
                this.certificado_seleccionado= {}
                this.metodos_pagos = []
                this.reset()
                this.$swal.fire({
                    icon: "success",
                    title: "¡Proceso realizado con éxito!",
                    html: `Gracias, te hemos enviado un correo con las indicaciones correspondientes. Su proceso de compra está en revisión.  <br>
                    <strong>Para acceder a su cuenta, utilice el número de documento o correo electrónico que registró e inicie sesión en 
                    <a href="https://certificadoitec.com/login" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-linkindex="0" id="LPlnk165511">https://certificadoitec.com/login</a></strong>
                    <br><br>
                    <strong style="font-size: 16px">En caso tener preguntas, puede escribirnos por WhatsApp al
                    <a href="https://wa.me/51976993697" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-linkindex="0" id="LPlnk165512">976993697 </a></strong>`
                });
            })
            .catch( error => {
                
                if(error.response.status == 500){
                    this.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response.data.message ?? 'Intente realizar esta operación más tarde.'
                    });
                }
                vuenisimo.error(error.response,this.form)
            })
            .finally(() => {
                vuenisimo.loader(this.form, false)
            })
        },

        reset(){
            this.at = 1
            this.id= null
            this.curso_detalle= {}
            this.certificado_seleccionado= {}
            this.metodos_pagos = []
            this.$refs.payForm.reset(); // Restablece el formulario
            this.title=  'Detalles - Cursos Grabados'
            this.selected_method = null
        },

        changes: function()
        {
            let data = vuenisimo.getFormData(this.form)
            vuenisimo.resetForm(this.form)
            this.form.elements[0].value = data.document
        },

        search: function()
        {
            vuenisimo.clearValidation(this.form)
            let data = vuenisimo.getFormData(this.form)
           
            if(data.document.length >= 8 && data.document.length <= 20)
            {
                this.loader = true
                axios.get(this.$uri+'/user/search-web/'+data.document)
                .then( response => {
                    if(response.data.user)
                    {
                        this.dissabled = true
                        this.user = response.data.user
                        vuenisimo.fillForm(this.form,this.user)
                        this.select = this.user.region
                    }
                    else
                    {
                        this.dissabled = false
                        vuenisimo.resetForm(this.form)
                        this.form.elements[0].value = data.document
                        this.select = null
                    }
                })
                .catch( error => {
                    this.dissabled = true
                    vuenisimo.error(error.response)
                })
                .finally(() => {

                    this.loader = false
                })
            }
            else
            {
                /* let value = event.target.value
                this.dissabled = true
                this.select = null
                vuenisimo.resetForm(this.form)
                event.target.value = value */
            }
        },

        removeApiFromUrl() {
           return this.$uri.replace('/api', '');
        },

        handleMethodChange(event) {
            const selectedIndex  = event.target.value;
            if(selectedIndex == '' | selectedIndex == undefined){
                this.selected_method = null
            }else{
                this.selected_method = this.metodos_pagos.find(method => method.id == selectedIndex);
            }
        },

        handleSelectionChange(selectedId) {
            this.curso_detalle.qualities = this.curso_detalle.qualities.map(element => {
                element.select = element.id === selectedId;
                return element;
            });
        },
        handleClick(e) {
       
            this.$swal.fire({
                imageUrl: this.removeApiFromUrl(this.$uri) + '/qualities/' + e,
                customClass: {
                popup: 'custom-swal'
                },
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                });
            // Por ejemplo, puedes abrir un modal, redirigir a otra ruta, etc.
        }
    },
}
</script>
<style>
    .linea-horizontal {
        border: none; /* Elimina el borde predeterminado */
        border-top: 2px dashed #dee2e6; /* Aplica una línea punteada de 1px de grosor */
        width: 100%; /* Ajusta el ancho según tus necesidades */
        margin: 10px 0; /* Espacio vertical alrededor de la línea */
    }
    th, td {
        border: none; /* Elimina los bordes de las celdas */
        padding-right: 4px;
        text-align: left; /* Alineación del texto */
    }
    div.lista ul {
        list-style: none; /* Elimina los estilos predeterminados de la lista */
        margin-left: -21px;
        font-size: 14px;
    }
    
    div.lista ul li {
        padding-left: 25px; /* Agrega espacio a la izquierda para el icono y el texto */
        position: relative; /* Establece la posición relativa para que los elementos secundarios se posicionen en relación con este */
    }
    div.lista ul li::before {
        content: "\f111"; /* Código del icono de Font Awesome */
        font-family: 'Font Awesome\ 5 Free'; /* Fuente de Font Awesome */
        font-weight: 800; /* Peso de la fuente */
        margin-right: 5px; /* Espacio entre el icono y el texto */
        color: #17c1e8; /* Color del icono */
        font-size: 13px;
        position: absolute; /* Establece la posición absoluta para que el icono no afecte al flujo del texto */
        left: 0; /* Alinea el icono a la izquierda del <li> */
        top: 6%; /* Alinea verticalmente el icono */
        /*transform: translateY(-50%); Corrige el desplazamiento vertical del icono */
    }

    div.temario ul {
        list-style: none; /* Elimina los estilos predeterminados de la temario */
        margin-left: -21px;
        font-size: 14px;
    }
    
    div.temario ul li {
        padding-left: 25px; /* Agrega espacio a la izquierda para el icono y el texto */
        position: relative; /* Establece la posición relativa para que los elementos secundarios se posicionen en relación con este */
    }
    div.temario ul li::before {
        content: "\f00c"; /* Código del icono de Font Awesome */
        font-family: 'Font Awesome\ 5 Free'; /* Fuente de Font Awesome */
        font-weight: 800; /* Peso de la fuente */
        margin-right: 5px; /* Espacio entre el icono y el texto */
        color: green; /* Color del icono */
        font-size: 13px;
        position: absolute; /* Establece la posición absoluta para que el icono no afecte al flujo del texto */
        left: 0; /* Alinea el icono a la izquierda del <li> */
        top: 6%; /* Alinea verticalmente el icono */
        /*transform: translateY(-50%); Corrige el desplazamiento vertical del icono */
    }
    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%); /* Color de fondo */
      color: white; /* Color del texto */
      font-size: 14px; /* Tamaño del texto */
      cursor: default; /* Cursor por defecto */
    }

    .custom-swal {
        padding: 0;
        max-width: none;
        width: 50%;
        padding-left: 23px;
        padding-right: 23px;
        padding-top: 23px;
    }

    .custom-swal img {
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;

    }
    @media (max-width: 576px) {
        .swal2-popup.swal2-modal.custom-swal.swal2-show {
            width: 100% !important;
        }

        .swal2-popup.swal2-modal.custom-swal.swal2-show .swal2-confirm {
            height: 30px; 
            padding: 0 10px;
            display: flex;
            align-items: center; 
            justify-content: center;
        }
    }
</style>